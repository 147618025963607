import './privacyPolicy.scss';

const webSite = <a href="https://app.coffeeunity.ru/">https://app.coffeeunity.ru/</a>;
const mailHelp = <a href="mailto:help@hohoro.ru">help@hohoro.ru</a>;

const PrivacyPolicy = (props: {
    isShowBackButton: boolean
}) => {

	return <>
		<ul>
			<li><strong>Общие положения</strong></li>
		</ul>
		<p>Настоящая Политика в отношении обработки персональных данных (далее &ndash; Политика) разработана в соответствии с положениями Конституции Российской Федерации, Федерального закона от 27 июля 2006 г. № 149-ФЗ &laquo;Об информации, информационных технологиях и о защите информации&raquo;, Федерального закона от 27 июля 2006 г. № 152-ФЗ &laquo;О персональных данных&raquo; (далее &ndash; Федеральный закон) и иными нормативными правовыми актами и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые&nbsp;<strong>АО &laquo;Вендинг будущего&raquo; (ОГРН 1227700898413, ИНН 9719034830) (далее &ndash; &laquo;Оператор&raquo;).</strong></p>
		<p>1.1. Настоящая Политика применяется ко всей информации, которой Оператор может получить в ходе своей деятельности от физических лиц – посетителей сайта {webSite} в том числе пользователей мобильного приложения CoffeeUnity, ссылки на которое размещены на сайте {webSite}.</p>
		<p>1.2. Настоящая Политика устанавливает порядок получения, защиты, хранения, обработки и передачи персональных данных Пользователей, действует в отношении всей информации, которую Оператор может получить о Пользователях.</p>
		<p>Настоящая Политика не распространяется на другие сайты и не применяется в отношении сайтов третьих лиц. Администрация сайта не несет ответственность за сайты третьих лиц, на которые Пользователи могут перейти по ссылкам, доступным на сайте</p>
		<p>1.3. К персональным данным Пользователей сайта и мобильного приложения CoffeeUnity относятся:</p>
		<p>фамилия, имя, отчество;</p>
		<p>электронный адрес;</p>
		<p>номера телефонов;</p>
		<p>дата рождения;</p>
		<p>город (место нахождения);</p>
		<p>история покупок, история и баланс бонусных баллов (кэшбек);</p>
		<p>данные платежных карт, иная платежная информация, предоставленная пользователем (более подробно использование данной информации, указано в документе «Условия использования банковских карт» размещенном на сайте {webSite}</p>
		<p>информация, полученная в результате сбора и обработки обезличенных данных о посетителях (в т.ч. файлов &laquo;cookie&raquo;) с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).</p>
		<p>Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</p>
		<p>1.4. Персональные данные Пользователей являются конфиденциальной информацией и не могут быть использованы Оператором или любым иным лицом в личных целях.</p>
		<p>1.5. Согласие на обработку персональных данных, разрешенных для распространения, Пользователь предоставляет Оператору непосредственно.</p>
		<p>1.6. Передача (распространение, предоставление, доступ) персональных данных, разрешенных Пользователем для распространения, должна быть прекращена в любое время по требованию Пользователя. Данное требование должно включать в себя фамилию, имя, отчество (при наличии), контактную информацию (номер телефона, адрес электронной почты или почтовый адрес) субъекта персональных данных, а также перечень персональных данных, обработка которых подлежит прекращению. Указанные в данном требовании персональные данные могут обрабатываться только Оператором, которому оно направлено.</p>
		<p>1.7. Согласие на обработку персональных данных, разрешенных для распространения, прекращает свое действие с момента поступления Оператору требования, указанного в п. 1.6. настоящей Политики в отношении обработки персональных данных.</p>
		<p>Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено федеральным законом.</p>
		<p>1.8 Администрация сайта {webSite} (далее – «Администрация сайта») и мобильного приложения CoffeeUnity (далее – «мобильное приложение»), ссылки на которое размещены на сайте, обеспечивает пользователям свободный бесплатный доступ к своим персональным данным, включая право на получение копий любой записи, содержащей их персональные данные, за исключением случаев, предусмотренных законодательством.</p>
		<p>1.9. Администрация сайта и мобильного приложения разрабатывает меры защиты персональных данных пользователей. сайта.</p>
		{/*  */}
		<p>2. Основные понятия, используемые в Политике Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;</p>
		<p>Администрация сайта и мобильного приложения – уполномоченные на управление сайтом работники, определяющие состав персональных данных Пользователей, цели сбора персональных данных, их обработку и хранение;</p>
		<p>Сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу {webSite};</p>
		<p>Мобильное приложение CoffeeUnity - программное обеспечение, специально разработанное под конкретную мобильную платформу (iOS, Android, и т. д.). Предназначеное для использования на смартфонах, планшетах, умных часах и других мобильных устройствах;</p>
		<p>Пользователь – физическое лицо, имеющее доступ к сайту {webSite} и/или мобильному приложению,  использующее мобильное приложение и/или данный сайт для своих целей, а так же в целях осуществления предпринимательской деятельности, и предоставивший Оператору необходимые персональные данные;</p>
		<p>Персональные данные – любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (Пользователю), которая сама по себе или в сочетании с другой информацией, имеющейся в распоряжении сайта и/или,мобильного приложения позволяет идентифицировать личность пользователя;</p>
		<p>Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
		{/*  */}
		<ul>
			<li><strong>3. Основные права и обязанности Оператора</strong></li>
		</ul>
		<p>3.1. Оператор имеет право:</p>
		<p>&ndash; получать от Пользователя достоверные информацию и/или документы, содержащие персональные данные;</p>
		<p>&ndash; в случае отзыва Пользователем согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия Пользователя при наличии оснований, указанных в Федеральном законе;</p>
		<p>&ndash; самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Федеральным законом и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Федеральным законом или другими федеральными законами.</p>
		<p>3.2. Оператор обязан:</p>
		<p>&ndash; предоставлять Пользователю по его просьбе информацию, касающуюся обработки его персональных данных;</p>
		<p>&ndash; организовывать обработку персональных данных в порядке, установленном действующим законодательством Российской Федерации;</p>
		<p>&ndash; отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Федерального закона;</p>
		<p>&ndash; сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 30 дней с даты получения такого запроса;</p>
		<p>&ndash; публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных данных;</p>
		<p>&ndash; принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;</p>
		<p>&ndash; прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Федеральным законом;</p>
		<p>&ndash; исполнять иные обязанности, предусмотренные Федеральным законом.</p>
		{/*  */}
		<ul>
			<li><strong>4. Основные права и обязанности Пользователей</strong></li>
		</ul>
		<p>4.1. Пользователи имеют право:</p>
		<p>&ndash; ознакомиться с информацией, которую Оператор хранит о конкретном Пользователе.</p>
		<p>&ndash; получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных законодательством Российской Федерации. Сведения предоставляются Пользователю Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Федеральным законом;</p>
		<p>&ndash; требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;</p>
		<p>&ndash; на отзыв согласия на обработку персональных данных;</p>
		<p>&ndash; обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;</p>
		<p>&ndash; на осуществление иных прав, предусмотренных законодательством Российской Федерации.</p>
		<p>4.2. Пользователи обязаны:</p>
		<p>&ndash; предоставлять Оператору достоверные данные о себе;</p>
		<p>&ndash; сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.</p>
		<p>4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством Российской Федерации.</p>
		{/*  */}
		<ul>
			<li><strong>5. Принципы обработки персональных данных</strong></li>
		</ul>
		<p>5.1. Обработка персональных данных осуществляется на законной и справедливой основе.</p>
		<p>5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>
		<p>5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.</p>
		<p>5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
		<p>5.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.</p>
		<p>5.6. Оператор не проверяет достоверность предоставленной Пользователем персональной информации и не осуществляет контроль её актуальности.</p>
		<p>Оператор исходит из того, что Пользователь предоставляет достоверную персональную информацию и поддерживает эту информацию в актуальном состоянии.</p>
		<p>Всю ответственность, а также возможные последствия предоставления недостоверной или неактуальной персональной информации несёт Пользователь.</p>
		<p>5.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, либо договором, стороной которого является Пользователь. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</p>
		{/*  */}
		<ul>
			<li><strong>6. Цели обработки персональных данных</strong></li>
		</ul>
		<p>6.1. Цель обработки персональных данных Пользователя:</p>
		<p>- идентификации Пользователя, зарегистрированного на сайте {webSite}, и/или мобильном приложении для оформления заказа и/ (или) заключения договора купли-продажи товара дистанционным способом, возможного участия в акциях и иных мероприятиях предусмотренных для пользователей;</p>
		<p>- предоставления Пользователю доступа к персонализированным ресурсам сайта {webSite} и/или мобильного приложения CoffeeUnity;</p>
		<p>- установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта {webSite}, мобильного приложения, оказания услуг, обработки запросов и заявок от Пользователя;</p>
		<p>- создания учетной записи для совершения покупок и использования функционала мобильного приложения;</p>
		<p>- уведомления Пользователя сайта {webSite} и/или мобильного приложения о состоянии заказа;</p>
		<p>- предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием сайта {webSite} и/или мобильного приложения;</p>
		<p>- предоставления Пользователю обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Оператора или от имени партнеров Оператора;</p>
		<p>- осуществление рекламной деятельности;</p>
		<p>- на проведение опросов и маркетинговых, статистических и других исследований.</p>
		<p>6.2. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты&nbsp; {webSite} &nbsp;с пометкой &laquo;Отказ от уведомлений о новых продуктах и услугах и специальных предложениях&raquo;.</p>
		<p>6.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте и/или мобильном приложении, улучшения качества сайта и его содержания.</p>
		<p>6.4. Оператор не обрабатывает сведения, касающиеся расовой и национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни Пользователя.</p>
		{/*  */}
		<ul>
			<li><strong>7. Правовые основания обработки персональных данных</strong></li>
		</ul>
		<p>7.1. Правовыми основаниями обработки персональных данных Оператором являются:</p>
		<p>&ndash; договоры, заключаемые между оператором и субъектом персональных данных;</p>
		<p>&ndash; федеральные законы, иные нормативно-правовые акты в сфере защиты персональных данных;</p>
		<p>&ndash; Пользовательское соглашение на обработку персональных данных.</p>
		<p>7.2. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте {webSite}, мобильном приложении или направленные Оператору лично, либо посредством электронной почты. Заполняя соответствующие формы и/или отправляя (предоставляя) свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</p>
		<p>7.3. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов &laquo;cookie&raquo; и использование технологии JavaScript).</p>
		<p>7.4. Пользователь самостоятельно принимает решение о предоставлении его персональных данных и дает согласие свободно, своей волей и в своем интересе.</p>
		<ul>
			<li><strong>8. Порядок сбора, хранения, передачи и других видов обработки персональных данных</strong></li>
		</ul>
		<p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
		<p>8.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
		<p>8.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если Пользователем дано согласие Оператору на передачу данных третьему лицу.</p>
		<p>8.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их путем направления Оператору уведомление на адрес электронной почты Оператора&nbsp;{mailHelp}&nbsp;с пометкой &laquo;Актуализация персональных данных&raquo;.</p>
		<p>8.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством.</p>
		<p>Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора&nbsp;{mailHelp}&nbsp;с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.</p>
		<p>8.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (Операторами) в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Субъект персональных данных и/или Пользователь обязан самостоятельно своевременно ознакомиться с указанными документами. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте поставщиков услуг.</p>
		<p>8.6. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения, не действуют в случаях обработки персональных данных в государственных, общественных и иных публичных интересах, определенных законодательством Российской Федерации.</p>
		<p>8.7. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем, по которому является Пользователь. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</p>
		<p>8.8. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия субъекта персональных данных или отзыв согласия субъектом персональных данных, а также выявление неправомерной обработки персональных данных.</p>
		<ul>
			<li><strong>9. Перечень действий, производимых Оператором с полученными персональными данными</strong></li>
		</ul>
		<p>9.1. Оператор совершает любые действия (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.</p>
		<p>9.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.</p>
		<ul>
			<li><strong>10. Ответственность за нарушение норм, регулирующих обработку и защиту персональных данных Пользователей</strong></li>
		</ul>
		<p>10.1. Лица, виновные в нарушении норм, регулирующих получение, обработку и защиту персональных данных Пользователей, привлекаются к дисциплинарной, материальной, гражданско-правовой, административной и уголовной ответственности в порядке, установленном действующим законодательством Российской Федерации.</p>
		<p>10.2. Моральный вред, причиненный Пользователям вследствие нарушения их прав, нарушения правил обработки персональных данных, установленных законодательством Российской Федерации, подлежит возмещению в соответствии с законодательством Российской Федерации.</p>
		<ul>
			<li><strong>11. Заключительные положения</strong></li>
		</ul>
		<p>11.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты&nbsp;{mailHelp}.</p>
		<p>11.2. Оператор вправе вносить изменения в настоящую Политику без согласия Пользователя.</p>
		<p>11.3. Новая Политика вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики.</p>
		<p>11.4. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
		<p>11.5. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу&nbsp;{mailHelp}.</p>
	</>
};

export default PrivacyPolicy;
