import './contactInfo.scss';
import {Map, Placemark, YMaps} from 'react-yandex-maps';
import icon_mail from '../../../assets/img/icon_mail.svg';
import icon_phone from '../../../assets/img/icon_phone.svg';

const ContactInfoView = (props: {
    isShowBackButton: boolean
}) => {

    return <>
        <div className="mb-4">
            Есть вопросы? Есть ответы!
        </div>
        <b>Телефон</b>
        <div className="mt-3 mb-4">
            <img className="mr-2" alt="" src={icon_phone}/> <a href="callto:88002345977" className="link"> 8 (800)
            234-59-77</a>
        </div>
        <b>E-mail</b>
        <div className="mt-3 mb-4">
            <img className="mr-2" alt="" src={icon_mail}/> <a href="mailto:help@hohoro.ru"
                                                              className="link">help@hohoro.ru</a>
        </div>
        <b>Адрес</b>
        <div className="mt-3 mb-4">
            г. Москва, ул. Большая Семеновская д. 32, стр. 3
        </div>
        <YMaps className="w-100">
            <Map width="100%"
                 defaultState={{
                     center: [55.781388, 37.707790],
                     zoom: 12,
                 }}>
                <Placemark geometry={[55.781388, 37.707790]}/>
            </Map>
        </YMaps>
    </>
};

export default ContactInfoView;
