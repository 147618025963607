import React, { useState } from 'react';
import './styles/App.scss';
import logo from './assets/img/double_logo.svg';
import google_play from './assets/img/gogle-play-rus.svg';
import app_store from './assets/img/app-store-rus.svg';
import card1 from './assets/img/card1.png';
import card2 from './assets/img/card2.png';
import iphone from './assets/img/iphone.png';
import icon1 from './assets/img/icon1.png';
import icon2 from './assets/img/icon2.png';
import icon3 from './assets/img/icon3.png';
import icon_cards from './assets/img/logotypes.svg';
import Contact from "./componets/info/contact/contactInfo";
import ModalWindow from "./componets/modalView/modalWindow";
import LegalInformationInfo from "./componets/info/legalInformationInfo/legalInformationInfo";
import PaymentInfo from "./componets/info/paymentInfo/paymentInfo";
import PrivacyPolicy from "./componets/info/privacyPolicy/privacyPolicy";
import ContractOfferInfo from "./componets/info/contractOfferInfo/contractOfferInfo";
import UserAgreementForTheUseOfPersonalData from "./componets/info/userAgreementForTheUseOfPersonalData/userAgreementForTheUseOfPersonalData";
import CardsAgreement from './componets/info/cardsAgreement/cardsAgreementView';
import HowGetHhrPolicy from './componets/info/howGetHhrPolicy/howGetHhrPolicyView';
import HowDeleteAppAccount from './componets/info/howDeleteAppAccount/howDeleteAppAccountView';

function App() {
	const [modalInfo, setModalInfo] = useState({
		contacts: false,
		payment: false,
		privacyPolicy: false,
		contractOffer: false,
		userAgreement: false,
		cardsAgreement: false,
		howGetHhrPolicy: false,
		howDeleteAppAccount: false,
	});

	const openModal = (modalName: any) => {
		setModalInfo((prevModalInfo) => ({ ...prevModalInfo, [modalName]: true }));
	};

	const closeModal = (modalName: any) => {
		setModalInfo((prevModalInfo) => ({ ...prevModalInfo, [modalName]: false }));
	};

	const contentContact = (
		<>
			<Contact isShowBackButton={false} />
			<div className="mt-3"></div>
			<LegalInformationInfo isShowBackButton={false} />
		</>
	);

	const contentPayment = (
		<PaymentInfo isShowBackButton={false} />
	);

	const contentPrivacyPolicy = (
		<PrivacyPolicy isShowBackButton={false} />
	);

	const contentContractOffer = (
		<ContractOfferInfo isShowBackButton={false} />
	);

	const contentUserAgreementForTheUseOfPersonalData = (
		<UserAgreementForTheUseOfPersonalData isShowBackButton={false} />
	);

	const contentCardsAgreement = (
		<CardsAgreement isShowBackButton={false} />
	);

	const contentHowGetHhrPolicy = (
		<HowGetHhrPolicy isShowBackButton={false} />
	);
	const contentHowDeleteAppAccount = (
		<HowDeleteAppAccount isShowBackButton={false} />
	);

	return (
		<div className="App">
			{modalInfo.contacts && (
				<ModalWindow
					children={contentContact}
					title="Контакты"
					onClose={() => closeModal('contacts')} />
			)}
			{modalInfo.payment && (
				<ModalWindow
					children={contentPayment}
					title="Оплата"
					onClose={() => closeModal('payment')} />
			)}
			{modalInfo.privacyPolicy && (
				<ModalWindow
					children={contentPrivacyPolicy}
					title="Политика конфиденциальности"
					onClose={() => closeModal('privacyPolicy')} />
			)}
			{modalInfo.contractOffer && (
				<ModalWindow
					children={contentContractOffer}
					title="Договор оферты"
					onClose={() => closeModal('contractOffer')} />
			)}
			{modalInfo.userAgreement && (
				<ModalWindow
					children={contentUserAgreementForTheUseOfPersonalData}
					title="Пользовательское соглашение на обработку персональных данных"
					onClose={() => closeModal('userAgreement')} />
			)}
			{modalInfo.cardsAgreement && (
				<ModalWindow
					children={contentCardsAgreement}
					title="Условия использования карт"
					onClose={() => closeModal('cardsAgreement')} />
			)}
			{modalInfo.howGetHhrPolicy && (
				<ModalWindow
					children={contentHowGetHhrPolicy}
					title="Правила начисления баллов"
					onClose={() => closeModal('howGetHhrPolicy')} />
			)}
			{modalInfo.howDeleteAppAccount && (
				<ModalWindow
					children={contentHowDeleteAppAccount}
					title="Как удалить аккаунт в приложении"
					onClose={() => closeModal('howDeleteAppAccount')} />
			)}

			<div className="container">
				<div className="logo"><img alt={'Hohoro'} src={logo} /></div>
				<div className="left-side">
					<div className="title">
						Последний шаг <br />
						и натуральный кофе <br />
						в твоём мобильном!
					</div>
					<div className="desc">
						Больше подарков и бонусов в приложении
					</div>
					<div className="market-links">
						<a href="https://play.google.com/store/apps/details?id=com.whiteFrame.Hohoro"
							target={'_blank'}
							className='market'><img alt="Google play" className="google" src={google_play} /></a>
						<a href="https://apps.apple.com/ru/app/hohoro/id1593892116"
							target={'_blank'}
							className='market'><img
								alt="App Store" className="apple" src={app_store} /></a>
					</div>
				</div>
				<div className="right-side">
					<div className="animate card_1">
						<img alt="Hohoro" src={card1} />
					</div>
					<div className="animate card_2">
						<img alt="Hohoro" src={card2} />
					</div>
					<div className="animate iphone">
						<img alt="Hohoro" src={iphone} />
					</div>
				</div>
				<div className="bottom">
					<div className="bottom-container">
						<div className='bar-item'>
							<div className="bar-content">
								<div className="bar-icon">
									<img alt='Hohoro' src={icon1} />
								</div>
								Скачивай и регистрируйся <br />
								в приложении CoffeeUnity
							</div>
						</div>
						<div className='bar-item'>
							<div className="bar-content">
								<div className="bar-icon">
									<img alt='Hohoro' src={icon2} />
								</div>
								Получай бонусы в подарок
							</div>
						</div>
						<div className='bar-item'>
							<div className="bar-content">
								<div className="bar-icon">
									<img alt='Hohoro' src={icon3} />
								</div>
								Каждый шестой стаканчик <br />
								бесплатно
							</div>
						</div>
					</div>
					<div className="links-container">
						<div className="links">
							<div className="links-item" 
								onClick={() => openModal('contacts')}>
								Контакты
							</div>
							<div className="links-item" 
								onClick={() => openModal('payment')}>
								Оплата
							</div>
							<div className="links-item"
								onClick={() => openModal('privacyPolicy')}>
								Политика конфиденциальности
							</div>
							<div className="links-item"
								onClick={() => openModal('contractOffer')}>
								Договор оферты
							</div>
							<div className="links-item"
								onClick={() => openModal('userAgreement')}>
								Пользовательское соглашение
							</div>
							<div className="links-item"
								onClick={() => openModal('cardsAgreement')}>
								Условия использования карт
							</div>
							<div className="links-item"
								onClick={() => openModal('howGetHhrPolicy')}>
								Правила начисления Баллов
							</div>
							<div className="links-item"
								onClick={() => openModal('howDeleteAppAccount')}>
								Как удалить аккаунт в приложении
							</div>
						</div>
						<div className="card-icons">
							<img src={icon_cards} alt="Card Icons" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;