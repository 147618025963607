import {useEffect, useState} from 'react';
import ModalWindowView from './modalWindowView';

const ModalWindow = (props: { children: JSX.Element | undefined, onClose: () => void, title: string }) => {
    useEffect(() => {
        const onCloseWindow = (event: any) => {
            console.log('onCloseWindow');
            const children = event.target.children;
            if (children.idModalBody) {
                if (props.onClose) {
                    props.onClose();
                }
            }
            return;
        }
        setTimeout(() => {
            return document.addEventListener('click', onCloseWindow, false)
        }, 0);
        return () => {
            document.removeEventListener('click', onCloseWindow, false);
        }
    }, [props]);

    return <>
        <ModalWindowView>
            <div className="header">
                <div className="modal-title">
                    {props.title}
                </div>
                <div className="close" onClick={() => props.onClose()}></div>
            </div>
            <div className="content">
                {props.children}
            </div>
        </ModalWindowView>
    </>
}

export default ModalWindow;