import './modalWindow.scss';

const ModalWindowView = ({ children }: any) => {
    return (
        <div id="idModal" className="modal-bg">
            <div id="idModalBody" className="modal-container">
                <div className='modal'>
                    {children}
                </div>
            </div>
            {/* <button className="btn btn-close"></button> */}
        </div>
    );
}

export default ModalWindowView;
