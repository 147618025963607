const HowGetHhrPolicy = (props: {
	isShowBackButton: boolean
}) => {
	return <>
		<p><strong>Что такое Баллы?</strong></p>
		<p>Баллы - это виртуальная валюта, которая позволяет приобрести товары в мобильном приложении CoffeeUnity.</p>
		<p><strong>Как начисляются Баллы?</strong></p>
		<ul>
			<li>- При регистрации в приложении по номеру телефона – начисляется единоразово 50 (пятьдесят) Баллов.</li>
			<li>- За заполнения полностью профиля - начисляется единоразово 70 (семьдесят) Баллов.</li>
			<li>- За приглашенного по реферальному коду пользователя – начисляется единоразово 100 (сто) Баллов при условии, что такой новый пользователь совершил одну и более покупок с использованием банковской карты. Количество таких единоразовых выплат за приглашенных пользователей ограничено 5 (пять) приглашениями.</li>
			<li>- За регистрацию в мобильном приложении по ссылке - начисляется единоразово 50 (пятьдесят) Баллов, при условии, что лимит в 5 (пять) приглашений указанный в предыдущем пункте, не превышен.</li>
			<li>- За использование промокода введенного в приложении, количество Баллов определяется тем, кто выпустил промокод.</li>
			<li>- Единоразово начисляется 20% от стоимости каждого заказа приобретенного с использованием банковской карты, в виде Баллов, с округлением до ближайшего целого числа, приобретенного с использованием банковской карты. Например, если 20% от стоимости заказа в виде Баллов равно 1,5 Балла, то Участнику начисляется 2 Балла; если 20% от стоимости заказа в виде Баллов равно 1,3 Баллы, то Участнику начисляется 1 Балл.</li>
			<li>- После выполнения Заданий, которые могут быть продуктовыми (например, купить какой-либо конкретный товар) и социальными (например, сделать фотографию).</li>
			<li>- Баллы не начисляются на суммы скидок, предоставляемых в рамках Программы в результате списания Баллов, а также в рамках иных программ лояльности.</li>
		</ul>
		<p><strong>Как можно списать Баллы?</strong></p>
		<ul>
			<li>- Баллы можно списать только на определенный список товаров, указанный в Мобильном приложении.</li>
			<li>- Минимальная стоимость заказа, в рамках которого списываются Баллы, равна не менее, чем 1 руб.</li>
		</ul>
	</>
};

export default HowGetHhrPolicy;
