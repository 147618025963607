import './paymentInfo.scss';

import payment_methods from './../../../assets/img/logotypes.svg';

const PaymentInfoView = (props: {
	isShowBackButton: boolean
}) => {

	return <>
		<div className="info">
			<p>Обращаем внимание, покупка может быть осуществлена исключительно в момент, когда вы находитесь рядом с
				торговым аппаратом.</p>
			<p>Для покупки товара по программе лояльности вам необходимо авторизоваться/зарегистрироваться на сайте,
				указав свою почту и/или телефон.</p>
			<p>После авторизации вам необходимо нажать кнопку “Выбрать кофе”, а затем сканировать QR-код, размещенный на
				автомате перед вами. Если у вас нет возможности отсканировать QR-код посредством камеры, вы можете
				ввести код автомата вручную. Обязательно проверьте адрес автомата, выведенный на экране вашего телефона,
				и установите стаканчик в автомат. Далее выберите желаемый напиток и способ оплаты.</p>
			<p>Наслаждайтесь ароматом свежесваренного кофе!</p>
			<h2>Ассортимент и стоимость</h2>
			<p>Мы предлагаем вам большой ассортимент напитков, таких как американо, капучино, горячий шоколад, какао,
				мокачино, флэт уайт, латте, раф банан и эспрессо, стоимость напитков установлена от 100 руб.</p>
			<p>Обращаем ваше внимание, что ассортимент в разных торговых автоматах может отличаться.</p>
			<h2>Оплата</h2>
			<img className="mb-3" width="100%" alt="" src={payment_methods} />
			<h2>Уважаемый клиент!</h2>
			<p>Вы можете оплатить свой заказ онлайн с помощью банковской карты через платежный сервис компании
				Tinkoff. После подтверждения заказа Вы будете перенаправлены на защищенную платежную страницу
				Tinkoff, где необходимо будет ввести данные для оплаты заказа. После успешной оплаты на указанную в
				форме оплаты электронную почту будет направлен электронный чек с информацией о заказе и данными по
				произведенной оплате.</p>
			<h2>Гарантии безопасности</h2>
			<p>Безопасность процессинга Tinkoff подтверждена сертификатом стандарта безопасности данных индустрии
				платежных карт PCI DSS. Надежность сервиса обеспечивается интеллектуальной системой мониторинга
				мошеннических операций, а также применением 3D Secure - современной технологией безопасности
				интернет-платежей.</p>
			<p>Данные Вашей карты вводятся на специальной защищенной платежной странице. Передача информации в
				процессинговую компанию Tinkoff происходит с применением технологии шифрования TLS. Дальнейшая
				передача информации осуществляется по закрытым банковским каналам, имеющим наивысший уровень
				надежности.</p>
			<p><b><i>Tinkoff не передает данные Вашей карты магазину и иным третьим лицам!</i></b></p>
			<p>Если Ваша карта поддерживает технологию 3D Secure, для осуществления платежа, Вам необходимо будет пройти
				дополнительную проверку пользователя в банке-эмитенте (банк, который выпустил Вашу карту). Для этого Вы
				будете направлены на страницу банка, выдавшего карту. Вид проверки зависит от банка. Как правило, это
				дополнительный пароль, который отправляется в SMS, карта переменных кодов, либо другие способы.</p>
			<h2>Возврат</h2>
			<p>При неуспешной покупке денежные средства возвращаются автоматически. Возврат денежных средств
				осуществляется на ту же банковскую карту, с которой производился платеж. Возврат денежных средств на
				карту осуществляется в срок от 5 до 30 банковских дней, в зависимости от Банка, которым была выпущена
				банковская карта.</p>
		</div>
	</>
};

export default PaymentInfoView;
