import './cardsAgreement.scss';

const webSite = <a href="https://app.coffeeunity.ru/">https://app.coffeeunity.ru/</a>;
const mailHelp = <a href="mailto:help@hohoro.ru">help@hohoro.ru</a>;

const CardsAgreement = (props: {
	isShowBackButton: boolean
}) => {
	return <>
		<ul>
			<li><strong>1. Общие положения</strong></li>
		</ul>
		<p>1.1. Акционерное общество "Вендинг будущего" (далее – АО "Вендинг будущего") предлагает пользователю сети Интернет (далее – «Пользователь») возможность вводить и сохранять данные электронных средств платежа, включая данные банковских карт, управлять такими данными, использовать их в целях совершения безналичной оплаты в адрес АО "Вендинг будущего", его аффилированных лиц и третьих лиц с использованием функций мобильного приложения CoffeeUnity (далее – «Приложение CoffeeUnity) ссылка на которое размещено на сайте {webSite}, а также иные возможности, связанные с использованием данных электронных средств платежа, привязанных Пользователем к учетной записи в приложении CoffeeUnity (далее – «Привязанная карта»).</p>
		<p>1.2. Использование Привязанных карт в Приложении CoffeeUnity регулируется настоящими условиями использования привязанных карт (далее - «Условия»), а также документами (далее – «Регулирующие документы»):</p>
		<ul>

			<li>- Пользовательским соглашением {webSite}</li>
			<li>- Политикой конфиденциальности {webSite}</li>
		</ul>
		<p>1.3. Осуществляя привязку какого-либо из электронных средств платежа к учетной записи Пользователя в Приложении CoffeeUnity, Пользователь считается принявшим настоящие Условия, а также условия Регулирующих документов, в полном объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений указанных документов Пользователь не вправе осуществлять привязку электронного средства платежа.</p>
		<p>1.4. Настоящие Условия и Регулирующие документы могут быть изменены АО "Вендинг будущего" без какого-либо специального уведомления Пользователя, новая редакция Условий и Регулирующих документов вступает в силу с момента ее размещения в сети Интернет по адресу настоящих Условий: {webSite}, или адресу Регулирующего документа, указанного в п. 1.2 Условий.</p>
		<p>1.5. В случае если АО "Вендинг будущего" были внесены какие-либо изменения в Условия или Регулирующие документы в порядке, предусмотренном п. 1.4. Условий, с которыми Пользователь не согласен, он обязан прекратить добавление Привязанной карты и/или удалить ранее добавленные Привязанные карты.</p>
		<p>1.6. Пользователь дает свое согласие на получение сообщений информационного характера, связанных с использованием Привязанной карты. Пользователь вправе отказаться от получения сообщений, следуя инструкциям, указанным в полученном сообщении.</p>
		<ul>
			<li><strong>2. Привязанная карта. Условия добавления Привязанной карты</strong></li>
		</ul>
		<p>2.1. Добавление Привязанной карты доступно Пользователю при условии прохождения им процедуры регистрации в Приложении CoffeeUnity, согласно условиям Пользовательского соглашения {webSite}, а также последующей авторизации в Приложении CoffeeUnity с использованием зарегистрированной учетной записи Пользователя Приложения CoffeeUnity</p>
		<p>2.2. Для привязки банковской карты Пользователю, прошедшему процедуру авторизации в соответствии с требованиями п. 2.1. Условий, необходимо указать в точке привязки банковской карты в Приложении CoffeeUnity данные банковской карты, используемой для оплаты, а именно:</p>
		<ul>
			<li>- Номер банковской карты;</li>
			<li>- Срок окончания действия банковской карты;</li>
			<li>- Имя и фамилию владельца банковской карты (указанные на карте);</li>
			<li>- Защитный код (CVV/CVC);</li>
			<li>- Адрес электронной почты Пользователя;</li>
			<li>- Иная информация, указание которой предусмотрено в точке привязки банковской карты в Приложении CoffeeUnity.</li>
		</ul>
		<p>Под точкой привязки банковской карты Пользователя понимается любая платежная форма в Приложении CoffeeUnity, а также любые другие платежные формы, отображаемые в Приложении CoffeeUnity, используемые для совершения безналичной оплаты в адрес АО "Вендинг будущего", его аффилированных лиц, или третьих лиц, а также специальный раздел «Карты» в настройках Приложения CoffeeUnity.</p>
		<p>2.3. Осуществляя привязку электронного средства платежа, Пользователь гарантирует:</p>
		<ul>
			<li>2.3.1. что является дееспособным лицом, достигшим возраста необходимого для использования Приложения CoffeeUnity и для совершения соответствующих сделок, либо заручился согласием законного представителя;</li>
			<li>2.3.2. является действительным владельцем Привязанной карты, выданной на его имя</li>
			<li>2.3.3. использование им Привязанной карты не будет осуществляться в незаконных или мошеннических целях или иными способами, нарушающими положения законодательства Российской Федерации, права и свободы третьих лиц, а также настоящие Условия и положения Регулирующих документов;</li>
			<li>2.3.4. указание им достоверной и полной информации о Привязанной карте; соблюдение им правил платежных систем и требований банка-эмитента, выпустившего карту или иное электронное средство платежа, в том числе в отношении порядка проведения безналичных расчетов;</li>
			<li>2.3.5. что предпринимает достаточные меры для защиты аккаунта в Приложении CoffeeUnity (доступа к Приложению CoffeeUnity), к которому осуществляется привязка электронного средства платежа, препятствующие доступу третьих лиц к данным Пользователя в Приложении CoffeeUnity и совершению несанкционированной оплаты от имени Пользователя;</li>
			<li>2.3.6. предпринимает достаточные меры для защиты данных электронного средства платежа, препятствующие доступу третьих лиц к ним для совершения оплаты от имени Пользователя;</li>
			<li>2.3.7. что при привязке электронного средства платежа и совершении оплаты Пользователь внимательно ознакомился (будет знакомиться) с информацией об условиях списания, оформления подписок в Приложении CoffeeUnity (если таковые будут доступны), о лицах, в пользу которых осуществляется списание, о приобретаемых товарах и/или услугах, сумме, подлежащей списанию и иной значимой для совершения операции с использованием Привязанной карты информацией.</li>
		</ul>
		<p>2.4. При добавлении Привязанной карты может быть осуществлено пробное списание суммы, необходимое для подтверждения точности и действительности данных Привязанной карты. В случае успешного прохождения транзакции указанная сумма возвращается Пользователю. Неуспешная попытка списания указанной суммы означает невозможность добавления данной Привязанной карты.</p>
		<p>2.5. АО "Вендинг будущего"  не гарантирует добавление электронного средства платежа Пользователя в качестве Привязанной карты и возможность ее последующего использования в Приложении CoffeeUnity. АО "Вендинг будущего"  вправе самостоятельно устанавливать ограничения относительно возможности привязки и использования карт и иных электронных средств платежа определенных банков-эмитентов или платежных систем, банковских карт, выпущенных иностранными банками-эмитентами, и иные ограничения, касающиеся возможности использования электронных средств платежа в Приложении CoffeeUnity.</p>
		<p>2.6. При условии прохождения электронным средством платежа проверки, установленной п. 2.4. Условий (если применимо) и отсутствия ограничений, указанных в п. 2.5. Условий, указанное Пользователем электронное средство платежа приобретает статус Привязанной карты.</p>
		<p>Информация о Привязанных картах Пользователя, а также возможность управления Привязанными картами (добавление новых Привязанных карт, удаление ранее добавленных Привязанных карт, изменение информации о Привязанных картах) доступны пользователю в специальном разделе в настройках Приложения CoffeeUnity.</p>
		<p>2.7. АО "Вендинг будущего"  оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных Привязанной карты и запросить в связи с этим документы (в частности, документы, удостоверяющие личность), непредоставление которых, по усмотрению АО "Вендинг будущего", может быть приравнено к предоставлению недостоверной информации и повлечь отказ в возможности добавления Привязанной карты или последствия, указанные в п. 4.6 Условий.</p>
		<ul>
			<li><strong>3. Условия использования Привязанной карты</strong></li>
		</ul>
		<p>3.1. Если иное не предусмотрено условиями использования Приложения CoffeeUnity, списание с Привязанной карты производится путем формирования Пользователем распоряжения в целях осуществления перевода денежных средств, инициируемым Пользователем с использованием выбранной им Привязанной карты через платежную форму в Приложении CoffeeUnity.</p>
		<p>Принимая настоящие условия, Пользователь дает согласие, что в случае отсутствия достаточных средств для совершения оплаты с использованием Привязанной карты в Приложении CoffeeUnity или образования задолженности Пользователя ввиду невозможности оплаты с использованием конкретной банковской карты в Приложения CoffeeUnity. АО "Вендинг будущего"  вправе списать сумму задолженности с любой из Привязанных карт. При этом Пользователь соглашается, что при добавлении новой карты и наличия на ней суммы, достаточной для погашения задолженности, АО "Вендинг будущего"  вправе списать такую сумму автоматически. Пользователь признает, что распоряжения на списание денежных средств с его Привязанной карты, направленные в соответствии с настоящим пунктом Условий, являются распоряжениями самого Пользователя, а действия лиц, участвующих в проведении платежной операции (банка эквайера, процессингового центра и т.д.), направленные на списание денежных средств в соответствии с настоящим пунктом Условий, выполнены с согласия Пользователя.</p>
		<p>3.2. АО "Вендинг будущего"  вправе самостоятельно определять технические условия обслуживания Привязанных карт, исходя из требований максимальной эффективности и безопасности хранения и использования данных Привязанных карт.</p>
		<p>АО "Вендинг будущего"  вправе производить токенизацию Привязанных банковских карт Пользователей для обеспечения дополнительной защиты хранения и использования данных Привязанных карт Пользователей.</p>
		<p>При этом под токеном понимается цифровое представление данных Привязанной карты, используемое для осуществления платежа с использованием Привязанной карты. Токен не является электронным средством платежа или платежным приложением и не имеет материального носителя. К отношениям по осуществлению платежа с использованием токена в равной степени применимы настоящие Условия.</p>
		<p>Создание токена может осуществляться платежной системой, в рамках которой выпущена банковская карта Пользователя. Для создания, обеспечения использования, отзыва токена в платежную систему (в зависимости от платежной системы, в рамках которой выпущена Привязанная карта, включая платежные системы (VISA (Visa International Service Association, 900 Metro Center Blvd, Foster City, CA 94404 U.S.A и ее аффилированные лица), MasterCard (Mastercard International Incorporated, 2000 Purchase Street Purchase, NY 10577 U.S.A. и ее аффилированные лица) (в случае доступности) или МИР (Акционерное общество «Национальная система платежных карт», 115184, г. Москва, ул. Большая Татарская, д. 11 и ее аффилированные лица) передаются данные банковской карты, указанные в п. 2.2. Условий.</p>
		<p>3.4. Пользователь понимает и соглашается, что все действия, совершенные с использованием Привязанной карты в Приложении CoffeeUnity, считаются совершенными Пользователем.</p>
		<p>При наличии у Пользователя оснований полагать, что неуполномоченное лицо получило доступ к Привязанной карте или к Приложению CoffeeUnity Пользователя, Пользователь обязуется принять необходимые меры по защите данных электронных средств платежа от их неправомерного использования путем обращения в банк-эмитент, смены пароля, обращения в службу поддержки Приложения CoffeeUnity (в случае доступа третьих лиц к Приложению CoffeeUnity Пользователя).</p>
		<p>В случае обращения Пользователя (владельца электронного средства платежа) о возврате денежных средств, списанных, как следует из обращения, с Привязанной карты (или его банковской карты, или Счёта в Приложении CoffeeUnity, если они не были ранее привязаны к Приложению CoffeeUnity) неуполномоченным лицом без распоряжения Пользователя (владельца электронного средства платежа), АО "Вендинг будущего"  вправе отказать в возврате денежных средств, если из обстоятельств следует, что лицо, инициировавшее списание, было надлежаще авторизовано в Приложении CoffeeUnity и/или предоставило полные данные электронного средства платежа Пользователя, включая CVV/CVC-код банковской карты (если применимо).</p>
		<p>Для выяснения обстоятельств списания, защиты прав и проведения расследования в отношении использования электронного средства платежа неуполномоченным лицом, Пользователь вправе обратиться в банк-эмитент электронного средства платежа, а также в правоохранительные органы.</p>
		<p>3.5. Пользователю может быть доступна информация о совершенных им покупках в Приложении CoffeeUnity с использованием Привязанной карты в специальном разделе личного кабинета Пользователя.</p>
		<ul>
			<li><strong>4. Ограничение ответственности</strong></li>
		</ul>
		<p>4.1. АО "Вендинг будущего"  не оказывает платных услуг Пользователю в рамках функциональности по использованию Привязанных карт. При осуществлении оплаты Пользователь вступает в прямые договорные отношения с лицом, в адрес которого производится списание с Привязанной карты.</p>
		<p>При осуществлении списания в адрес третьего лица, АО "Вендинг будущего"  не несет ответственности за достоверность информации, указанной данным лицом, а также не гарантирует его добросовестность, качество реализуемых им товаров и оказываемых услуг, иных предметов платежа, фактическое исполнение им обязательств перед Пользователем, а также не является лицом, участвующим в урегулировании споров, вытекающих из договорных отношений между Пользователем и лицом-получателем платежа.</p>
		<p>4.2. АО "Вендинг будущего"  не участвует в заключении договора между Пользователем и получателем платежа, не уполномочен на получение и доведение информации о товарах, услугах и/или иных предметах платежа до Пользователя, не предоставляет Пользователю возможность ознакомиться с предложением получателя платежа о заключении договора купли-продажи (договора возмездного оказания услуг, договора о реализации иного предмета платежа) в отношении товаров, услуг и/или иных предметов платежа, не является владельцем агрегатора информации о товарах (услугах) в значении, предусмотренном Законом РФ от 07.02.1992 N 2300-1 «О защите прав потребителей», в отношениях с Пользователем, возникающих при использовании функциональности по использованию Привязанных карт.</p>
		<p>4.3. Пункты 4.1 и 4.2 неприменимы в случае, если продавцом и лицом-получателем платежа выступает АО "Вендинг будущего", либо АО "Вендинг будущего"  несет соответствующие обязательства согласно прямому указанию в условиях использования Приложения CoffeeUnity или требований законодательства.</p>
		<p>4.4. Функциональность по добавлению и использованию Привязанной карты не является электронным средством платежа или платежным приложением. АО "Вендинг будущего":</p>
		<ul>
			<li>не обеспечивает прием электронных средств платежа, осуществление перевода денежных средств и/или предоставление платежного приложения клиентам оператора по переводу денежных средств;</li>
			<li>не оказывает услуг обмена информацией при осуществлении операций с использованием электронных средств платежа между оператором по переводу денежных средств и его клиентами и (или) между оператором по переводу денежных средств и иностранным поставщиком платежных услуг;</li>
			<li>не является банковским платежным агентом (субагентом), платежным агрегатором, поставщиком платежного приложения, оператором услуг информационного обмена или лицом, привлеченным оператором по переводу денежных средств в целях обеспечения приема электронных средств платежа;</li>
			<li>не обеспечивает и не осуществляет идентификацию Пользователей в порядке, предусмотренном Федеральным законом «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма» от 07.08.2001 г. № 115-ФЗ;</li>
			<li>не обеспечивает и не осуществляет авторизацию платежа, не отвечает за ее осуществление или ее результат.</li>
		</ul>
		<p>4.5. Функциональность по добавлению и использованию Привязанной карты предоставляется «как есть». АО "Вендинг будущего"  не гарантирует соответствие данных возможностей целям и ожиданиям Пользователя, бесперебойную и безошибочную работу такой функциональности или ее доступность в любое время.</p>
		<p>4.6. АО "Вендинг будущего" оставляет за собой право на свое усмотрение ограничить доступ Пользователя к указанным в настоящих Условиях функциональным возможностям по добавлению и использованию Привязанных карт (или к определенным функциям) при нарушении настоящих Условий или Регулирующих документов, а равно как в случае, если Пользователь действует недобросовестно, и/или его действия / бездействие нарушает или создает угрозу нарушения прав и законных интересов АО "Вендинг будущего", Пользователей и/или третьих лиц.</p>
		<ul>
			<li><strong>5. Данные Пользователя. Иные положения</strong></li>
		</ul>
		<p>5.1. Выполнение функций может требовать наличие доступа к сети Интернет. Пользователь самостоятельно получает и оплачивает такой доступ на условиях и по тарифам своего оператора связи или провайдера доступа к сети Интернет.</p>
		<p>5.2. Пользователь подтверждает свое согласие с тем, что переданные им при добавлении и использовании Привязанных карт данные, включая данные, перечисленные в п. 2.2 и п.2.2.1 настоящих Условий, а также данные, ранее указанные Пользователем в Приложении CoffeeUnity или полученные АО "Вендинг будущего"  в соответствии с п. 2.7 настоящих Условий, могут быть использованы АО "Вендинг будущего"  для целей предоставления описанной в настоящих Условиях функциональности, а также в порядке и в целях, указанных в Политике конфиденциальности {webSite}.</p>
		<p>АО "Вендинг будущего"  осуществляет обработку персональной информации, указанной Пользователем, в соответствии с требованиями российского законодательства и Политики конфиденциальности {webSite}.</p>
		<p>В рамках использования Привязанных карт в Приложении CoffeeUnity данные Пользователя могут быть переданы третьим лицам для целей совершения платежа, включая (но не ограничиваясь) платежные системы, банки, иных лиц, участвующих в проведении платежной операции.</p>
		<p>АО "Вендинг будущего" вправе осуществлять передачу данных Пользователей (номера карт Пользователей) платежным системам в порядке и для целей, предусмотренных п. 3.2. Условий, а также получать и обрабатывать агрегированную информацию, полученную от платежных систем для целей предоставления возможности использования Привязанных карт в Приложении CoffeeUnity, персонализации Приложения CoffeeUnity, выявления угроз, проведения аналитических и статистических исследований на основе обезличенных данных, а также для иных целей, предусмотренных Политикой конфиденциальности {webSite}.</p>
		<p>5.3. Все вопросы и претензии, связанные с добавлением/использованием Привязанных карт, а также возможным нарушением Пользователями законодательства и/или прав третьих лиц, должны направляться через форму обратной связи по адресу: {mailHelp}.</p>
		<p>5.4. Информация о АО "Вендинг будущего":</p>
		<ul>
			<li>АКЦИОНЕРНОЕ ОБЩЕСТВО «ВЕНДИНГ БУДУЩЕГО»</li>
			<li>Юридический адрес организации</li>
			<li>107023 г. Москва, ул. Большая Семеновская д. 32, стр. 3, этаж 1, помещ. 1, ком. 1-2</li>
			<li>ИНН 9719034830</li>
			<li>КПП 771901001</li>
			<li>ОГРН 1227700898413</li>
		</ul>
	</>
};

export default CardsAgreement;
