import "./privacyPolicy.scss"
import PrivacyPolicyView from "./privacyPolicyView";


const PrivacyPolicy = (props: {
    isShowBackButton: boolean
}) => {
    return <PrivacyPolicyView isShowBackButton={props.isShowBackButton} />
}

export default PrivacyPolicy;
