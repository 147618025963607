import howDeleteAppAccount from '../../../assets/img/howDeleteAppAccount.png';

const HowDeleteAppAccount = (props: { isShowBackButton: boolean }) => {
  return (
    <>
      <p>Для удаления аккаунта в приложении вам нужно перейти в раздел настроек и выбрать пункт "Удалить аккаунт".</p>
      <img style={{ maxWidth: '100%' }} src={howDeleteAppAccount} alt="" />
      <p>После подтверждения ваш аккаунт будет удален.</p>
    </>
  );
};

export default HowDeleteAppAccount;
