import './legalInformationInfo.scss';

const LegalInformationInfoView = (props: {
    isShowBackButton: boolean
}) => {

    return <>
        <h2 className={`${props.isShowBackButton ? "pl-btn-back" : ""}`}>Информация по юр.лицу</h2>
        <b>Полное наименование организации</b>
        <div className="mt-3 mb-4">
            Акционерное общество "Вендинг будущего"
        </div>
        <b>Сокращенное наименование организации</b>
        <div className="mt-3 mb-4">
			АО "Вендинг будущего"
        </div>
        <b>ИНН</b>
        <div className="mt-3 mb-4">
            9719034830
        </div>
        <b>ОГРН</b>
        <div className="mt-3 mb-4">
            1227700898413
        </div>
        <b>Юридический адрес</b>
        <div className="mt-3 mb-4">
            107023 г. Москва, ул. Большая Семеновская д. 32, стр. 3, этаж 1, помещ. 1, ком. 1-2
        </div>
        <b>Фактический адрес</b>
        <div className="mt-3 mb-4">
            107023 г. Москва, ул. Большая Семеновская д. 32, стр. 3, этаж 1, помещ. 1, ком. 1-2
        </div>
        <b>Расчетный счет</b>
        <div className="mt-3 mb-4">
            40702810707500011707
        </div>
        <b>Наименование банка</b>
        <div className="mt-3 mb-4">
            АО «Тинькофф Банк»
        </div>
        <b>Корреспондентский счет</b>
        <div className="mt-3 mb-4">
            30101810145250000974
        </div>
        <b>БИК</b>
        <div className="mt-3 mb-4">
			044525974
        </div>
    </>
};

export default LegalInformationInfoView;
