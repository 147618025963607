import './userAgreementForTheUseOfPersonalData.scss';

const webSite = <a href="https://app.coffeeunity.ru/">https://app.coffeeunity.ru/</a>;
const mailHelp = <a href="mailto:help@hohoro.ru">help@hohoro.ru</a>;

const UserAgreementForTheUseOfPersonalDataView = (props: {
	isShowBackButton: boolean
}) => {

	return <>
		<p>1. Настоящее соглашение определяет условия и порядок обработки персональных данных пользователей сайта {webSite}, принадлежащего Акционерное общество "Вендинг будущего" (ОГРН 1227700898413, ИНН 9719034830)</p>
		{/*  */}
		<p>2. Принимая условия настоящего соглашения и проставляя «галочку» в соответствующем поле «Принимаю пользовательское соглашение», или начиная использование мобильного приложения CoffeeUnity, что является для целей настоящего соглашения его акцептом и полным принятием всех его условий, Пользователь:</p>
		<p>- выражает согласие с условиями обработки персональных данных;</p>
		<p>- подтверждает, что все указанные им данные принадлежат лично ему;</p>
		<p>- подтверждает и признает, что им внимательно в полном объеме прочитано настоящее соглашение, Политика в отношении обработки персональных данных и условия обработки его персональных данных, указываемых им в полях форм, текст соглашения и Политика в отношении обработки персональных данных, условия обработки персональных данных ему понятны, в том числе условия использования платежных данных и банковских карт;</p>
		<p>- дает свое согласие на обработку Оператором предоставляемых в составе информации персональных данных в целях, изложенных в пункте 2.1 Пользовательского соглашения на обработку персональных данных;</p>
		<p>- дает свое согласие Оператору на передачу персональных данных третьим лицам (далее – партнеры Оператора) в целях, изложенных в пункте 2.2 Пользовательского соглашения на обработку персональных данных;</p>
		<p>-дает согласие на направление информации о товарах и услугах, которые, по мнению Оператора, могут представлять интерес, предложений и другой информации;</p>
		<p>- дает согласие на проведение опросов и маркетинговых, статистических и других исследований.</p>
		<p>2.1. Оператор обрабатывает и использует персональные данные Пользователя в целях:</p>
		<p>- идентификации Пользователя, зарегистрированного на сайте {webSite}, мобильном приложении CoffeeUnity для оформления заказа и (или) заключения договора купли-продажи товара дистанционным способом;</p>
		<p>- предоставления Пользователю доступа к персонализированным ресурсам сайта {webSite} и/или мобильного приложения CoffeeUnity;</p>
		<p>- установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта {webSite} и/или мобильного приложения CoffeeUnity, оказания услуг, обработки запросов и заявок от Пользователя;</p>
		<p>- установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта {webSite}, оказания услуг, обработки запросов и заявок от Пользователя;</p>
		<p>- создания учетной записи для совершения покупок;</p>
		<p>- уведомления Пользователя сайта {webSite} и/или мобильного приложения CoffeeUnity о состоянии заказа;</p>
		<p>- предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием сайта {webSite} и/или мобильного приложения CoffeeUnity;</p>
		<p>- предоставления Пользователю обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Оператора или от имени партнеров Оператора;</p>
		<p>- осуществление рекламной деятельности.</p>
		<p>- на проведение опросов и маркетинговых, статистических и других исследований.</p>
		<p>В большинстве случаев Персональная информация обрабатывается автоматически без доступа к ней кого-либо из сотрудников Оператора. В случае если такой доступ понадобится, то он может быть предоставлен только тем сотрудникам Оператора, которые нуждаются в этом для выполнения своих задач. Для защиты и обеспечения конфиденциальности данных все сотрудники должны соблюдать внутренние правила и процедуры в отношении обработки Персональной информации. Они также должны следовать всем техническим и организационным мерам безопасности, действующим для защиты Персональной информации Пользователей.</p>
		<p>2.2. Передача Оператором персональных данных третьим лицам – партнерам Оператора осуществляется с целью предоставления Пользователю информации об обновленной продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени партнеров Оператора.</p>
		<p>Передаче подлежат следующие персональные данные Пользователя: фамилия, имя, отчество, контактный телефон, адрес электронной почты.</p>
		{/*  */}
		<p>3. Пользователь выражает свое согласие на обработку его персональных данных, а именно совершение любых действий (операций) или совокупности действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.</p>
		{/*  */}
		<p>4. Давая такое согласие, Пользователь подтверждает, что он действует свободно, своей волей и в своем интересе.</p>
		<p>Оператор не проверяет достоверность предоставленной Пользователем персональной информации и не осуществляет контроль её актуальности.</p>
		<p>Оператор исходит из того, что Пользователь предоставляет достоверную персональную информацию и поддерживает эту информацию в актуальном состоянии.</p>
		<p>Всю ответственность, а также возможные последствия предоставления недостоверной или неактуальной персональной информации несёт Пользователь.</p>
		<p>В случае выявления неточностей в персональных данных, Пользователь может актуализировать их путем направления Оператору уведомление на адрес электронной почты Оператора {mailHelp} с пометкой «Актуализация персональных данных».</p>
		{/*  */}
		<p>5. Согласие Пользователя на обработку персональных данных является конкретным, информированным и сознательным.</p>
		{/*  */}
		<p>6. Под персональными данными подразумевается любая информация личного характера, позволяющая установить личность Пользователя, такая как:</p>
		<p>- фамилия, имя, отчество;</p>
		<p>- дата рождения;</p>
		<p>- контактный телефон;</p>
		<p>- адрес электронной почты;</p>
		<p>- информация, полученная в результате сбора и обработки обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других);</p>
		<p>- история покупок, история и баланс бонусных баллов (кэшбек);</p>
		<p>- данные платежных карт, иная платежная информация, предоставленная пользователем (более подробно использование данной информации, указано в документе «Условия использования банковских карт» размещенном на сайте {webSite})</p>
		<p>- иная информация указанная в документах размещенных на сайте  {webSite} и входящая в перечень персональных данных или конфиденциальной информации о пользователе.</p>
		{/*  */}
		<p>8. Пользователь вправе в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию в личном кабинете сайта или мобильного приложения в части Ф. И. О., номера телефона, пароля, адреса, платежной информации и других данных доступных для изменения.</p>
		<p>9. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством.</p>
		<p>10. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора {mailHelp} с пометкой «Отзыв согласия на обработку персональных данных».</p>
		<p>11. Пользователь всегда может отказаться от получения рекламных рассылок, направив Оператору письмо на адрес электронной почты {mailHelp} с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».</p>
		<p>12. Оператор обязуется принимать все необходимые меры для защиты персональных данных Пользователя, предусмотренные действующим законодательством в области защиты персональных данных.</p>
		<p>13. Оператор обязуется соблюдать конфиденциальность персональных данных – не раскрывать третьим лицам и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено Федеральным законом от 27 июля 2006 г. № 152-ФЗ «О персональных данных».</p>
		<p>Настоящим Пользователь при создании личного кабинета при использовании сайта и/или ввода своих персональных данных как на сайте {webSite} так и в мобильном приложения CoffeeUnity соглашается полностью с условиями настоящего документа и подтверждает в том числе следующее: <br /> Я СОГЛАСЕН на обработку и хранение моих персональных данных, указанных мной в Форме обратной связи в соответствии с условиями настоящего согласия на обработку персональных данных, личном кабинете на сайте, мобильном приложении. Одновременно даю свое согласие и не возражаю, чтобы Оператор отправлял сообщения, в том числе информационного характера на номер телефона или адрес электронной почты или с использованием иных средств связи, указанных мной в Форме обратной связи.</p>

	</>
};

export default UserAgreementForTheUseOfPersonalDataView;
